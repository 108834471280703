import React from 'react'
import Helmet from 'react-helmet'

export default function Seo(props) {
  const {
    slug,
    title,
    description,
    fbappid = '2340847086201567',
    twitterUser = '1069914836597948416',
    SSLimage
  } = props

  const siteUrl = 'aboutpeople.info/'

  const image = props.image && props.image.file && props.image.file.url 

  return <Helmet>
      <html lang="ru-RU" />
      <title>{title}</title>

      <link rel="alternate" href={'https://www.' + siteUrl + (slug || '')} />
      <link rel="canonical" href={'https://' + siteUrl + (slug || '')} />

      {/* General tags */}
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta name="image" content={image ? `https:${image}` : SSLimage} />

      {/* Schema.org tags */}
      {/* <script type="application/ld+json">
				{JSON.stringify(schemaOrgJSONLD)}
			</script> */}

      {/* OpenGraph tags */}
      <meta property="og:url" content={'//' + siteUrl + (slug || '')} />
      <meta property="og:locale" content="ru_ru" />
      {slug ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image ? `https:${image}` : SSLimage} />
      <meta property="fb:app_id" content={fbappid} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUser} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image ? `https:${image}` : SSLimage} />
    </Helmet>
}
