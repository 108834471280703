import React, { useState } from 'react'
import { Link } from 'gatsby'
import Navigation from './Navigation';
import './Header.scss'
import cn from 'classnames'
import { setConfig } from 'react-hot-loader'
import { ReactComponent as LogoIcon } from '../../images/logo.svg'
import { ReactComponent as LogoSmall } from '../../images/logo-small.svg'
setConfig({ pureSFC: true })

const Header = ({
  about,
  slug,
  allContentfulTag
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const rubric = slug.split('/')[1]
  const handleNavClick = () => {
    isOpen && handelIsNavOpen()
    isOpen && setIsOpen(false)
  }

  const nav = {
    slug: slug,
    about: about,
    allContentfulTag: allContentfulTag,
    handleNavClick: handleNavClick,
  }
  const handelIsNavOpen = () => {
    const portalRoot = typeof document !== `undefined` ? document.getElementById('nav') : null
    portalRoot.classList.toggle("nav-open")
  }
    
  return <header className={cn('b-header')}>
      <Navigation {...nav}/>
        <div className="l-container l-container--base-width l-container--header">
          {slug !== '/' ? <Link onClick={() => handleNavClick()} role="link" aria-label="link to main page" to="/">
            <LogoSmall className="b-header__logo-small" />
            </Link> : <LogoIcon className="b-header__logo" />}
            {slug !== '/' && <nav className="section-navigation">
            <ul className="section-navigation__list">
              <li className="section-navigation__item">
                <Link className={cn("section-navigation__link", {
                  'section-navigation__link--active': slug.includes('news') && slug.split('/').length < 4
                })} role="link" aria-label="link to main page" to={'/' + rubric + '/news'}>
                  ПУБЛИКАЦИИ И СЮЖЕТЫ
                </Link>
              </li>
              <li className="section-navigation__item">
                <Link className={cn("section-navigation__link", {
                  'section-navigation__link--active': slug.includes('lessons') && slug.split('/').length < 4
                })} role="link" aria-label="link to main page" to={'/' + rubric  + '/lessons'}>
                  КАРТОЧКИ
                </Link>
              </li>
              <li className="section-navigation__item">
                <Link className={cn("section-navigation__link", {
                  'section-navigation__link--active': slug.includes('library') && slug.split('/').length < 4
                })} role="link" aria-label="link to main page" to={'/' + rubric + '/library'}>
                  БИБЛИОТЕКА
                </Link>
              </li>
              <li className="section-navigation__item">
                <Link className={cn("section-navigation__link", {
                  'section-navigation__link--active': slug.includes('video') && slug.split('/').length < 4
                })} role="link" aria-label="link to main page" to={'/' + rubric + '/video'}>
                  КИНОЗАЛ
                </Link>
              </li>
              <li className="section-navigation__item">
                <Link className={cn("section-navigation__link", {
                  'section-navigation__link--active': slug.includes('nko') && slug.split('/').length < 4
                })} role="link" aria-label="link to main page" to={'/' + rubric + '/nko'}>
                  КОНТАКТЫ ЭКСПЕРТОВ
                </Link>
              </li>
            </ul>
          </nav>}
         
          <button 
            onClick={() => { 
              handelIsNavOpen(!isOpen) 
              setIsOpen(!isOpen)
            }} 
            aria-hidden="true" 
            className={cn('b-header__burger hamburger hamburger--spin-r', {
              'is-active': isOpen,
              'b-header__burger--right': slug === '/'
            })} 
            type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
      </div>
    </header>
}

export default Header
