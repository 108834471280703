import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import { Link } from 'gatsby'

// Use a ternary operator to make sure that the document object is defined
const portalRoot = typeof document !== `undefined` ? document.getElementById('nav') : null
class Portal extends PureComponent {
 
  constructor() {
    super()
    // Use a ternary operator to make sure that the document object is defined
    this.el = typeof document !== `undefined` ? document.createElement('div') : null
  }
 
  componentDidMount = () => {    
    portalRoot.appendChild(this.el)
  }
 
  componentWillUnmount = () => {
    portalRoot.removeChild(this.el)
  }
 
  render() {
    const { children } = this.props
 
    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.el) {
      return ReactDOM.createPortal(children, this.el)
    } else {
      return null
    }
 
  }
}
export default class Navigation extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			activeCategory: "",
		}
	}
	componentDidMount() {
		this.setState({activeItem: this.props.slug.trim()})
		this.props.allContentfulTag.edges.map(items => {
			items.node.items.map(item => {
				if (item.slug === this.props.slug) {
					this.setState({
						activeCategory: items.node.title
					})
				}
			})
		})
	}
	
	render() {
		const {activeCategory} = this.state
		const {
			slug,
			about,
			handleNavClick,
		} = this.props
		const getCategotyName = contenfulID => (
			{
				ContentfulMediaLibrary: "Публикации и сюжеты",
				ContentfulLessons: "Карточки",
				ContentfulLibrary: "Библиотека",
				ContentfulVideoLibrary: "Кинозал",
				ContentfulNko: "Контакты экспертов",
			}[contenfulID]
		)
		return (
			<Portal>
				<nav role="navigation" className={cn('b-header__nav b-header-nav')}>
					<ul className="b-header__nav-list b-header-nav__list h-list-unstyled"> 
						{about && (
							<li className="b-header__nav-list-item b-header-nav__item">
								<Link
									className={cn('b-header-nav__link b-header-nav__link--header', {
										'b-header-nav__link--active':
											about.slug.trim() === slug.trim().substr(1),
									})}
									to={`/${about.slug}`}
									onClick={() => handleNavClick()}
								>
									{about.title}
								</Link>
							</li>
 						)}
						<li className="b-header__nav-list-item b-header-nav__item b-header-nav__item--seporator" />
						{this.props.allContentfulTag.edges.map((item, key) => {
							return (
							<li key={item.node.title + key} className="b-header__nav-list-item b-header-nav__item">
								<input
									className="h-visuallyhidden"
									type="radio"
									name={item.node.title + "-name"}
									id={item.node.title}
									checked={item.node.title === activeCategory}
									onChange={() => this.setState({activeCategory: item.node.title})}
								/>
								<label
									htmlFor={item.node.title}
									className="b-header-nav__link b-header-nav__sub-list--radio"
								>
									{item.node.displayTitle}
								</label>
									<ul
										id="cardsEl"
										className="b-header__nav-sub-list b-header-nav__sub-list"
									>
										{item.node &&
											item.node.items.map((item, key) =>{
												return (
													<li
														key={key}
														className="b-header__nav-sub-list-item b-header-nav__sub-item"
													>
														<Link
															className={cn('b-header-nav__sub-link', {
															'b-header-nav__link--active': slug === item.slug,
															})}
															onClick={() => handleNavClick()}
															to={`/${item.slug}`}
														>
															—&nbsp;{getCategotyName(item.__typename)}
														</Link>
													</li>
												)}
											)}
									</ul>
								</li>
							)
						})}
					</ul>
				</nav>
			</Portal>
		)
	}
}
